import React, { useEffect } from 'react'

import GeneralPageLayout from '../components/Layouts/GeneralPage'
import SectionContainer from '../components/UI/Containers/SectionContainer'
import './../styles/pageSpecific/ourTeam.scss'


const SearchResultsPage = () => {
    const pageInfo = {
        pageType:'interior',
        title1: 'Search',
        title2: 'Results',
        breadcrumbs: [''],
        ctaButton: false,
        ctaButtonData:{
            text: '',
            url: ''
          }
    }
 
    useEffect(() => {
        const script=document.createElement('script')
        script.src="https://cse.google.com/cse.js?cx=009164658937669078311:jk1fdjafiky"
        script.async=true;
        script.type = "text/javascript";
        let searchResults = document.querySelector('#searchResults')
        searchResults?.appendChild(script)
      return () => {
     
      }
    }, [])

    return(
        <GeneralPageLayout pageData={pageInfo}>
           <div className="content">
                <SectionContainer id='searchResults' color='white' type='contained' classes={['inlineChildren_mobile', 'narrowContainer']}>
                    <div className="gcse-searchresults-only" personalizedads="false"></div>
                </SectionContainer>
            </div> 
        </GeneralPageLayout>
    )
}

export default SearchResultsPage